import React, { FC, createContext, useContext } from 'react';
import { Outlet } from 'react-router-dom';

import {
  useEventsTable,
  useInsights,
  useInsightsFilters
  // useCharts,
} from '../hooks';

import DashboardViewContext from './dashboardViewContext';

export interface InsightsContextState {
  eventsTable: ReturnType<typeof useEventsTable>;
  insights: ReturnType<typeof useInsights>;
  filters: ReturnType<typeof useInsightsFilters>;
  // charts: ChartsState;
}

const InsightsContext = createContext<InsightsContextState>({} as InsightsContextState);

export const InsightsProvider: FC = () => {
  // the view contains the saved state (applied filters etc.) of the dashboard
  // which should be the default when the user first loads the page
  const view = useContext(DashboardViewContext);

  const filters = useInsightsFilters(view);
  const insights = useInsights({
    startDate: filters.startDate,
    endDate: filters.endDate,
    timeframe: filters.timeframe,
    teams: filters.teams,
    categories: filters.categories,
    locations: filters.locations
  });
  const eventsTable = useEventsTable({
    startDate: filters.startDate,
    endDate: filters.endDate,
    teams: filters.teams,
    categories: filters.categories,
    locations: filters.locations,
    timezones: filters.timezoneCodes,
    formats: filters.eventFormats
  });

  return (
    <InsightsContext.Provider
      value={{
        filters,
        eventsTable,
        insights
      }}
    >
      <Outlet />
    </InsightsContext.Provider>
  );
};

export default InsightsContext;
