import {
  DateRange,
  getDateAdjustedForTimezone
} from '@epoch-components/DateRangePicker/utils/dates';
import { getPresetRange, Timeframe } from '@epoch-components/DateRangePicker/utils/presets';
import { InsightsDashboardView } from 'types/event.types';

/**
 * Determine the start and end dates based on the filters coming from the backend.
 * This takes into account the timeframe that might've been set.
 * @param view the insights dashboard view containing saved filters etc
 * @returns
 */
export function getStartAndEndDates(view: InsightsDashboardView): DateRange {
  if (view.timeframe) {
    return getPresetRange(view.timeframe);
  }

  const dateFrom = view.startDate ?? new Date(new Date().setHours(0, 0, 0, 0));
  const dateTo = view.endDate ?? dateFrom;

  return {
    from: getDateAdjustedForTimezone(dateFrom),
    to: getDateAdjustedForTimezone(dateTo)
  } as DateRange;
}

/**
 * Takes the existing start and end dates that were set by the user and returns the dates for
 * the identical period right before. For example, if the user is looking at the past month,
 * the dates returned will be the month before the past month.
 * @param startDate
 * @param endDate
 */
export function getComparisonMetricsDates(
  startDate: Date | null,
  endDate: Date | null,
  timeframe: Timeframe | null
): { startDate: Date | null; endDate: Date | null } {
  if (!startDate || !endDate) {
    return { startDate: null, endDate: null };
  }

  // when timeframe is not set, we want to take the current time window and compare it to exact same one
  if (!timeframe) {
    const dateDifference = endDate.getTime() - startDate.getTime();
    const newStartDate = new Date(startDate.getTime() - dateDifference);
    const newEndDate = new Date(endDate.getTime() - dateDifference);

    return {
      startDate: newStartDate,
      endDate: newEndDate
    };
  }

  const newStartDate = new Date(startDate.getTime());
  const newEndDate = new Date(endDate.getTime());

  switch (timeframe) {
    case Timeframe.thisWeek:
      newStartDate.setDate(startDate.getDate() - 7);
      newEndDate.setDate(endDate.getDate() - 7);
      break;
    case Timeframe.lastWeek:
      newStartDate.setDate(startDate.getDate() - 7);
      newEndDate.setDate(endDate.getDate() - 7);
      break;
    case Timeframe.thisMonth:
      newStartDate.setMonth(startDate.getMonth() - 1);
      newEndDate.setMonth(endDate.getMonth() - 1);
      break;
    case Timeframe.lastMonth:
      newStartDate.setMonth(startDate.getMonth() - 1);
      newEndDate.setMonth(endDate.getMonth() - 1);
      break;
    case Timeframe.last3Months:
      newStartDate.setMonth(startDate.getMonth() - 3);
      newEndDate.setMonth(endDate.getMonth() - 3);
      break;
    case Timeframe.last6Months:
      newStartDate.setMonth(startDate.getMonth() - 6);
      newEndDate.setMonth(endDate.getMonth() - 6);
      break;
    case Timeframe.thisYear:
      newStartDate.setFullYear(startDate.getFullYear() - 1);
      newEndDate.setFullYear(endDate.getFullYear() - 1);
      break;
    case Timeframe.lastYear:
      newStartDate.setFullYear(startDate.getFullYear() - 1);
      newEndDate.setFullYear(endDate.getFullYear() - 1);
      break;
    default:
      return { startDate: null, endDate: null };
  }

  return {
    startDate: newStartDate,
    endDate: newEndDate
  };
}
