import gql from 'graphql-tag';

import { type InsightsDashboardView } from 'types/event.types';

export interface InsightsDashboardViewResult {
  insightsDashboardView: InsightsDashboardView;
}

export const INSIGHTS_DASHBOARD_VIEW_QUERY = gql`
  {
    insightsDashboardView {
      id
      startDate
      endDate
      timeframe
      eventFormats
      timezoneCodes
      categories
      locations
      teams
      showHeatmapColor
      eventTableColumnVisibility {
        attendance {
          visible
          sort
        }
        attendanceRate {
          visible
          sort
        }
        spend {
          visible
          sort
        }
        spendPerGuest {
          visible
          sort
        }
        nps {
          visible
          sort
        }
        satisfactionScore {
          visible
          sort
        }
        connectionScore {
          visible
          sort
        }
        retentionScore {
          visible
          sort
        }
        feedbackResponseRate {
          visible
          sort
        }
      }
    }
  }
`;

export interface TeamFilterResult {
  currentUserOrganizations: {
    id: number;
    name: string;
  }[];
}

export interface LocationFilterResult {
  companyLocations: {
    id: number;
    name: string;
  }[];
}

export interface CategoryFilterResult {
  companyTags: {
    id: number;
    name: string;
  }[];
}

export interface TimezoneFilterResult {
  companyEventsTimezones: {
    label: string;
    value: string;
  }[];
}

export const TIMEZONES_FILTER_QUERY = gql`
  query TimezoneFilter($searchText: String) {
    companyEventsTimezones(searchText: $searchText) {
      label
      value
    }
  }
`;

export const TEAM_FILTER_QUERY = gql`
  query TeamFilter($searchText: String) {
    currentUserOrganizations(searchText: $searchText) {
      id
      name
    }
  }
`;

export const LOCATION_FILTER_QUERY = gql`
  query LocationFilter($searchText: String) {
    companyLocations(excludeHidden: true, searchText: $searchText) {
      id
      name
    }
  }
`;

export const CATEGORY_FILTER_QUERY = gql`
  query CategoryFilter($searchText: String) {
    companyTags(searchText: $searchText) {
      id
      name
    }
  }
`;

export interface InsightsDashboardMetricsResult {
  insightsMetrics: {
    totalEvents: string;
    totalAttendance: string;
    averageNpsScore: string;
    employeeParticipation: string;
  };
}

export const INSIGHTS_DASHBOARD_METRICS = gql`
  query (
    $startDate: DateTime
    $endDate: DateTime
    $teams: [String]
    $categories: [String]
    $locations: [String]
  ) {
    insightsMetrics(
      startDate: $startDate
      endDate: $endDate
      teams: $teams
      categories: $categories
      locations: $locations
    ) {
      totalEvents
      totalAttendance
      averageNpsScore
      employeeParticipation
    }
  }
`;

export interface InsightsDashboardEventsResults {
  eventsInsights: {
    items: {
      id: number;
      title: string;
      date: Date;
      photoUrl: string;
      attendance: number;
      attendanceRate: number;
      spend: number;
      spendPerGuest: number;
      satisfactionScore: number;
      connectionScore: number;
      retentionScore: number;
      feedbackResponseRate: number;
      nps: {
        score: number;
      };
    }[];
    pages: number;
    total: number;
  };
}

export const INSIGHTS_DASHBOARD_EVENTS = gql`
  query (
    $startDate: DateTime
    $endDate: DateTime
    $teams: [String]
    $categories: [String]
    $locations: [String]
    $timezones: [String]
    $formats: [String]
    $page: Int
    $perPage: Int
    $sortBy: String
    $sortOrder: String
  ) {
    eventsInsights(
      startDate: $startDate
      endDate: $endDate
      teams: $teams
      categories: $categories
      locations: $locations
      timezones: $timezones
      formats: $formats
      page: $page
      perPage: $perPage
      sortBy: $sortBy
      sortOrder: $sortOrder
    ) {
      items {
        id
        title
        date
        photoUrl
        attendance
        attendanceRate
        spend
        spendPerGuest
        satisfactionScore
        connectionScore
        retentionScore
        feedbackResponseRate
        nps {
          score
        }
      }
      pages
      total
    }
  }
`;
