export const PRIMARY_BLUE = '#0987F2';
export const LIGHT_BLUE = '#e9f2fb';
export const LIGHTEST_BLUE = '#F7FAFD';

export const DARK_BLUE = '#094DF2';
export const PURPLE = '#3909F2';
export const LIGHT_PURPLE = '#FAECFF';
export const DARK_PURPLE = '#812A98';
export const TEAL = '#64FFDA';
export const GREEN = '#00c853';
export const RED = '#FC1F42';
export const BG_BLUE = '#F8FBFE';

export const WHITE = '#FFFFFF';
export const BLACK = '#090909';
export const DARK_BLACK = '#000000';

export const LIGHTEST_GREY = '#EFEFEF';
export const LIGHT_GREY = '#E7E7E7';
export const MEDIUM_GREY = '#767676';
export const DARK_GREY = '#2F2F2F';
export const BLUE_GREY = '#37474f';
export const NEW_GREY = '#D9D9D9';

export const PRIMARY_GREY = '#555555';
export const BORDER_GREY = '#D9D9D9';
export const MEDIUM_BORDER_GREY = '#C2C2C2';
export const BACKGROUND_GREY = '#F7F7F7';

export const BACKGROUND_BLUE = 'hsl(210, 69%, 95%)';

export const POSITIVE_GREEN = '#059450';
export const POSITIVE_GREEN_BG = '#E7F3EF';

export const ERROR_RED = '#DC4F4F';
export const ERROR_RED_BG = '#FFF0EE';

export const LINKEDIN_BLUE = '#4D81AE';
export const YELLOW_BG = '#FFFBF1';
export const YELLOW = '#BC8C2C';
