import { useMemo, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';

import { UPDATE_VIEW_FILTERS } from 'graphql/mutations/insights/insights.mutation';
import {
  TeamFilterResult,
  LocationFilterResult,
  CategoryFilterResult,
  TEAM_FILTER_QUERY,
  LOCATION_FILTER_QUERY,
  CATEGORY_FILTER_QUERY,
  TimezoneFilterResult,
  TIMEZONES_FILTER_QUERY
} from 'graphql/queries/insights/insights.query';
import { InsightsDashboardView } from 'types/event.types';
import { Timeframe, getPresetRange } from '@epoch-components/DateRangePicker/utils/presets';

import { getStartAndEndDates } from './utils';

import type { DateRange } from '@epoch-components/DateRangePicker/utils/dates';

export interface InsightsFiltersState {
  startDate: Date | null;
  endDate: Date | null;
  timeframe: Timeframe | null;
  teams: string[];
  categories: string[];
  locations: string[];
  timezoneCodes: string[];
  eventFormats: string[];
  searchTeamText: string;
  searchLocationText: string;
  searchCategoryText: string;
  data?: {
    currentUserOrganizations: TeamFilterResult['currentUserOrganizations'];
    companyLocations: LocationFilterResult['companyLocations'];
    companyTags: CategoryFilterResult['companyTags'];
    companyEventsTimezones: TimezoneFilterResult['companyEventsTimezones'];
  };
  loading: boolean;
  resetAll: () => void;
  updateDateRange: (options: { range: DateRange; timeframe: Timeframe | null }) => void;
  updateTeamsFilter: (teams: string[]) => void;
  updateCategoriesFilter: (categories: string[]) => void;
  updateLocationsFilter: (locations: string[]) => void;
  updateTimezoneCodesFilter: (timezones: string[]) => void;
  updateEventFormatsFilter: (eventFormats: string[]) => void;
  updateTeamSearchText: (text: string) => void;
  updateLocationSearchText: (text: string) => void;
  updateCategorySearchText: (text: string) => void;
  updateTimezonesSearchText: (text: string) => void;
}

export default function useInsightsFilters(view: InsightsDashboardView): InsightsFiltersState {
  const { from, to } = useMemo(
    () => getStartAndEndDates(view),
    [view.timeframe, view.startDate, view.endDate]
  );

  const [startDate, setStartDate] = useState<Date>(from);
  const [endDate, setEndDate] = useState<Date>(to);
  const [timeframe, setTimeframe] = useState<Timeframe | null>(view.timeframe);
  const [selectedTeams, setSelectedTeams] = useState<string[]>(view.teams || []);
  const [selectedEventFormats, setSelectedEventFormats] = useState<string[]>(
    view.eventFormats || []
  );
  const [selectedCategories, setSelectedCategories] = useState<string[]>(view.categories || []);
  const [selectedLocations, setSelectedLocations] = useState<string[]>(view.locations || []);
  const [selectedTimezoneCodes, setSelectedTimezoneCodes] = useState<string[]>(
    view.timezoneCodes || []
  );
  const [searchTeamText, setSearchTeamText] = useState<string>('');
  const [searchLocationText, setSearchLocationText] = useState<string>('');
  const [searchCategoryText, setSearchCategoryText] = useState<string>('');
  const [searchTimezoneText, setSearchTimezoneText] = useState<string>('');

  const {
    data: teamData,
    loading: teamLoading,
    refetch: refetchTeams
  } = useQuery<TeamFilterResult>(TEAM_FILTER_QUERY, {
    variables: { searchText: searchTeamText },
    fetchPolicy: 'network-only'
  });

  const {
    data: locationData,
    loading: locationLoading,
    refetch: refetchLocations
  } = useQuery<LocationFilterResult>(LOCATION_FILTER_QUERY, {
    variables: { searchText: searchLocationText },
    fetchPolicy: 'network-only'
  });

  const {
    data: categoryData,
    loading: categoryLoading,
    refetch: refetchCategories
  } = useQuery<CategoryFilterResult>(CATEGORY_FILTER_QUERY, {
    variables: { searchText: searchCategoryText },
    fetchPolicy: 'network-only'
  });

  const {
    data: timezoneData,
    loading: timezoneLoading,
    refetch: refetchTimezones
  } = useQuery<TimezoneFilterResult>(TIMEZONES_FILTER_QUERY, {
    variables: { searchText: searchTimezoneText },
    fetchPolicy: 'network-only'
  });

  const [updateViewFilters] = useMutation(UPDATE_VIEW_FILTERS, {
    onError: (err) => console.error(err)
  });

  const updateDateRange = (options: { range: DateRange; timeframe: Timeframe | null }): void => {
    setStartDate(options.range.from);
    setEndDate(options.range.to || null);
    setTimeframe(options.timeframe);

    updateViewFilters({
      variables: {
        startDate: options.range.from,
        endDate: options.range.to,
        timeframe: options.timeframe
      }
    });
  };

  const updateTeamsFilter = (teams: string[]): void => {
    setSelectedTeams(teams);

    updateViewFilters({
      variables: { teams }
    });
  };

  const updateCategoriesFilter = (categories: string[]): void => {
    setSelectedCategories(categories);

    updateViewFilters({
      variables: { categories }
    });
  };

  // TODO: Set up BE searchText filtering
  const updateLocationsFilter = (locations: string[]): void => {
    setSelectedLocations(locations);

    updateViewFilters({
      variables: { locations }
    });
  };

  const updateTimezoneCodesFilter = (timezoneCodes: string[]): void => {
    setSelectedTimezoneCodes(timezoneCodes);

    updateViewFilters({
      variables: { timezoneCodes }
    });
  };

  const updateEventFormatsFilter = (eventFormats: string[]): void => {
    setSelectedEventFormats(eventFormats);

    updateViewFilters({
      variables: { eventFormats }
    });
  };

  const resetAll = (): void => {
    const newTimeframe = Timeframe.last3Months;
    const newRange = getPresetRange(newTimeframe);

    // Update the state to trigger a re-render
    setStartDate(newRange.from);
    setEndDate(newRange.to);
    setTimeframe(newTimeframe);
    setSelectedTeams([]);
    setSelectedCategories([]);
    setSelectedLocations([]);
    setSearchTeamText('');
    setSearchLocationText('');
    setSearchCategoryText('');

    updateViewFilters({
      variables: {
        startDate: newRange.from,
        endDate: newRange.to,
        timeframe: newTimeframe,
        teams: [],
        categories: [],
        locations: []
      }
    });
  };

  const updateTeamSearchText = (text: string): void => {
    setSearchTeamText(text);
    refetchTeams({ searchText: text });
  };

  const updateLocationSearchText = (text: string): void => {
    setSearchLocationText(text);
    refetchLocations({ searchText: text });
  };

  const updateCategorySearchText = (text: string): void => {
    setSearchCategoryText(text);
    refetchCategories({ searchText: text });
  };

  const updateTimezonesSearchText = (text: string): void => {
    setSearchTimezoneText(text);
    refetchTimezones({ searchText: text });
  };

  return {
    startDate,
    endDate,
    timeframe,
    teams: selectedTeams,
    categories: selectedCategories,
    locations: selectedLocations,
    timezoneCodes: selectedTimezoneCodes,
    eventFormats: selectedEventFormats,
    searchTeamText,
    searchLocationText,
    searchCategoryText,
    data: {
      currentUserOrganizations: teamData?.currentUserOrganizations || [],
      companyLocations: locationData?.companyLocations || [],
      companyTags: categoryData?.companyTags || [],
      companyEventsTimezones: timezoneData?.companyEventsTimezones || []
    },
    // flags
    loading: teamLoading || locationLoading || categoryLoading || timezoneLoading,
    // handlers
    resetAll,
    updateDateRange,
    updateTeamsFilter,
    updateCategoriesFilter,
    updateLocationsFilter,
    updateTimezoneCodesFilter,
    updateEventFormatsFilter,
    updateTeamSearchText,
    updateLocationSearchText,
    updateCategorySearchText,
    updateTimezonesSearchText
  };
}
