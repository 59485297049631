import React, { FC, createContext } from 'react';
import { Outlet, Navigate } from 'react-router-dom';
import { useQuery } from '@apollo/client';

import {
  INSIGHTS_DASHBOARD_VIEW_QUERY,
  InsightsDashboardViewResult
} from 'graphql/queries/insights/insights.query';
import { EVENTS_LIST_ROUTE } from 'constants/routes';
import { InsightsDashboardView } from 'types/event.types';
import LoadingSpinner from 'components/common/loaders/LoadingSpinner';

export interface DashboardViewContextState {
  view: InsightsDashboardView;
}

const DashboardViewContext = createContext<InsightsDashboardView>({} as InsightsDashboardView);

export const DashboardViewProvider: FC = () => {
  const { data, loading } = useQuery<InsightsDashboardViewResult>(INSIGHTS_DASHBOARD_VIEW_QUERY, {
    fetchPolicy: 'network-only'
  });

  if (loading) {
    return <LoadingSpinner fullscreen />;
  }

  if (!data) {
    console.error('no dashboard view found.');
    return <Navigate to={EVENTS_LIST_ROUTE} />;
  }

  return (
    <DashboardViewContext.Provider value={data.insightsDashboardView}>
      <Outlet />
    </DashboardViewContext.Provider>
  );
};

export default DashboardViewContext;
