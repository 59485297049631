import gql from 'graphql-tag';

import { User } from 'types/user.types';

/**
 * Mutation variable type
 */
export interface USER_QUERY_VARS {
  id: number;
}

export interface USER_QUERY_RESULT {
  publicUser: User;
}

export const USER_QUERY = gql`
  query PublicUser($id: Int!) {
    publicUser(id: $id) {
      id
      firstName
      lastName
      role
      team
      profilePictureUrl
      company {
        id
        name
        locations {
          id
          name
          address
        }
      }
      companyLocationId
      location {
        id
        name
        address
        isActive
      }
      pastConfirmedEvents {
        id
        photoUrl
        title
        hostOrganizationName
        datetimeScheduled
        datetimeEnd
        locationName
        canSeeEventGuestList
        guestListTotals {
          confirmed
        }
        confirmedUsersSample {
          firstName
          lastName
        }
      }
    }
  }
`;

export const GLOBAL_USER_QUERY = gql`
  {
    globalUser {
      id
      firstName
      lastName
      email
      profilePictureUrl
      userRole
      role
      permissions
      team
      organizations {
        id
      }
      calendarId
      needsEmailVerification
      company {
        id
        name
        calendarEventAttendeeThreshold
        customContentName
        confirmedGuestsForAttendance
        slackEnabled
        distrolistsEnabled
        slackInfo {
          enterpriseName
          isEnterpriseInstall
          requiresUpdate
          workspaceName
        }
        locations {
          id
          name
          address
        }
        subdomain
        assets {
          logo {
            url
          }
          navLogo {
            url
          }
          discoverBanner {
            url
          }
        }
        discoverTabSettings
      }
      location {
        id
        name
        address
        isActive
      }
      workosEnabled
      hasGoogleCalendarIntegration
      companyUsesGoogleCalendar
      companyUsesOutlookCalendar
      hasSetUpOutlookCalendar
      identityProvider
      distrolistsEnabled
      distrolistsIdentityProvider
      featureFlags
      settings {
        frequency
        eventsEmail
        eventsSlack
      }
      hasCompletedZoomSetup
    }
  }
`;

export const PROFILE_EVENTS_QUERY = gql`
  {
    globalUser {
      futureConfirmedEvents {
        id
        photoUrl
        title
        isVirtual
        isInPerson
        hostOrganizationName
        datetimeScheduled
        datetimeEnd
        locationName
        canSeeEventGuestList
        guestListTotals {
          confirmed
        }
        confirmedUsersSample {
          firstName
          lastName
        }
        organization {
          id
          name
        }
        tags
      }
      pastConfirmedEvents {
        id
        photoUrl
        title
        isVirtual
        isInPerson
        hostOrganizationName
        datetimeScheduled
        datetimeEnd
        locationName
        canSeeEventGuestList
        guestListTotals {
          confirmed
        }
        confirmedUsersSample {
          firstName
          lastName
        }
        organization {
          id
          name
        }
        tags
      }
    }
  }
`;
